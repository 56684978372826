export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const apiConfig = {
  // https://api.onevillage.co/ for production, https://api.dev.onevillage.co/ for staging, etc
  apiUrl: process.env.REACT_APP_API_URL || 'https://localhost:44363/'
}

export const oidcConfig = {
  // In production: 'https://parent-account.onevillage.co/'
  stsAuthority: process.env.REACT_APP_OIDC_PARENT_AUTHORITY || 'https://localhost:44312/',
  clientId: process.env.REACT_APP_OIDC_PARENT_CLIENT_ID || 'OneVillage_Parent',
  // In production: 'https://parent.onevillage.co/'
  clientRoot: process.env.REACT_APP_OIDC_PARENT_CLIENT_ROOT || 'http://localhost:3001/',
  clientScope: 'openid profile email OneVillage'
}
