import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { IEnrollmentProgramSchedule, EnrollmentProgramScheduleService } from 'shared';

export interface ProgramsState {
  enrollmentSchedules: IEnrollmentProgramSchedule;
  payment: {
    stripePublishableKey?: string,
    clientSecret?: string
  }
};

const initialState: ProgramsState = {
  enrollmentSchedules: {},
  payment: {}
};
  
const slice = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    setEnrollmentSchedules(state: ProgramsState, action: PayloadAction<IEnrollmentProgramSchedule>) {
      state.enrollmentSchedules = action.payload;
    },
    setPaymentInfo(state: ProgramsState, action: PayloadAction<{ stripePublishableKey?: string, clientSecret?: string }>) {
      state.payment = action.payload;
    }
  }
});

export const reducer = slice.reducer;

const enrollmentProgramScheduleService = EnrollmentProgramScheduleService.getInstance<EnrollmentProgramScheduleService>();

export const getEnrollmentSchedules = (request: any = {}): AppThunk => async (dispatch) => {
  return enrollmentProgramScheduleService.getByChildAge(request).then((response) => {
    dispatch(slice.actions.setEnrollmentSchedules(response.data[0]));
    return response;
  })
}

export const setPaymentInfo = (data): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setPaymentInfo(data));
  }
}