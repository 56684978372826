import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import jwt_decode from 'jwt-decode';
import { AuthService } from 'src/lib/authService';

const authService = AuthService.getInstance();
interface AuthGuardProps {
  children?: ReactNode;
}

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, accessToken, logout, setLogin } = useAuth();
  const location = useLocation()
  const history = useHistory()
  if (accessToken) {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('accessToken')) {
      queryParams.delete('accessToken');
      history.replace({
        search: queryParams.toString(),
      })
    }
    try {
      const date = new Date(0);
      const decoded: any = jwt_decode(accessToken);
      date.setUTCSeconds(decoded.exp);
      if (date < new Date()) {
        logout()
      }
    } catch (err) {
      logout()
    }
  }

  const passedAccessToken = getParameterByName('accessToken');

  const getUser = async (token) => {
    const user = await authService.getOVUser(token);
    await setLogin(token, user);
  }
  

  if (!isAuthenticated && !passedAccessToken) {
    return <Redirect to="/login" />;
  } else if(!isAuthenticated && passedAccessToken) {
    window.history.pushState({}, '', window.location.pathname);
    localStorage.setItem('accessToken', passedAccessToken);
    getUser(passedAccessToken).then(() => {
      return (
        <>
          {children}
        </>
      );
    })
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
