import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { IParent, IParentProvider, IChild, ParentService, ChildService, DealChildEnrollmentClassService } from 'shared';
import findIndex from 'lodash/findIndex';

export interface CurrentUserState {
  parent: IParent;
  parentCart: any;
  provider: IParentProvider;
  selected: IChild[];
  showRegistration: boolean;
};

const initialState: CurrentUserState = {
  parent: null,
  provider: null,
  showRegistration: false,
  parentCart: {},
  selected: []
};

const slice = createSlice({
  name: 'current-user',
  initialState,
  reducers: {
    setCurrentUser(state: CurrentUserState, action: PayloadAction<{ parent: IParent; }>) {
      const { parent } = action.payload;
      state.parent = parent;
      state.showRegistration = false;
    },
    setProvider(state: CurrentUserState, action: PayloadAction<IParentProvider>) {
      state.provider = action.payload;
    },
    setSelected(state: CurrentUserState, action: PayloadAction<IChild[]>) {
      state.selected = action.payload;
    },
    setParentCartItem(state: CurrentUserState, action: PayloadAction<any>) {
      state.parentCart = action.payload;
    },
    setRegistrationFlow(state: CurrentUserState, action: PayloadAction<any>) {
      state.showRegistration = action.payload
    }
  }
});

export const reducer = slice.reducer;

const parentService = ParentService.getInstance<ParentService>();
const childService = ChildService.getInstance<ChildService>();
const dealChildEnrollmentClassService = DealChildEnrollmentClassService.getInstance<DealChildEnrollmentClassService>();

export const getUserById = (id): AppThunk => {
  return async (dispatch) => {
    parentService.get(id).then((response) => {
      dispatch(slice.actions.setCurrentUser({ parent: response.data }));
    });
  }
}

export const getParentCartItem = (): AppThunk => {
  return async (dispatch) => {
    dealChildEnrollmentClassService.getParentCart({ Type: 1 }).then((response) => {
      dispatch(slice.actions.setParentCartItem(response.data));
    });
  }
}

export const updateCurrentUser = (data): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setCurrentUser({ parent: data }));
  }
}

export const setProvider = (data): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setProvider(data));
  }
}

export const setRegistrationFlow = (data): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setRegistrationFlow(data));
  }
}

export const addChildrenItem = (request: any = {}, callback: any): AppThunk => async (dispatch, getState) => {
  const state = getState().currentUser;
  const list = Object.assign([], state?.parent?.children);
  return childService.createParentChild(request).then(({ data }) => {
    dispatch(slice.actions.setCurrentUser({
      parent: {
        ...state.parent,
        children: list.concat(data),
      }
    }));
    callback();
    return data;
  })
}

export const updateChildrenItem = (request: any = {}, callback: any): AppThunk => async (dispatch, getState) => {
  const state = getState().currentUser;
  const list = Object.assign([], state?.parent?.children);
  return childService.updateParentChild(request).then(({ data }) => {
    dispatch(slice.actions.setCurrentUser({
      parent: {
        ...state.parent,
        children: list.map((item) => {
          if (item.id === data.id) {
            return data;
          }
          return item
        }),
      }
    }));
    callback();
    return data;
  })
}

export const setSelectedItem = (children: any): AppThunk => (dispatch, getState) => {
  const state = getState().currentUser;
  const list = Object.assign([], state.selected);
  const currentIndex = findIndex(list, (item) => item.id === children.id);
  if (currentIndex !== -1) {
    list.splice(currentIndex, 1);
  } else {
    list.push(children);
  }
  dispatch(slice.actions.setSelected(list));
}

export default slice;
