import React, { FC, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import { useSelector } from 'src/store';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 96,
    height: 48,
    padding: 4,
    marginLeft: theme.spacing(2),
    background: '#FBFBFB',
    boxShadow: '0px 4px 15px rgba(196, 196, 196, 0.25)',
    borderRadius: 54
  },
  avatar: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  arrowIcon: {
    fontSize: 36,
    color: '#C4C4C4'
  }
}));

interface AccountProps {
  handleLogout?: () => void;
}

const Account: FC<AccountProps> = ({ handleLogout }) => {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const { parent } = useSelector(state => state.currentUser);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Box
        className={classes.root}
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src={parent?.avatar} />
        <KeyboardArrowDownIcon className={classes.arrowIcon} />
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/app/my-info/personal-info">Profile</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
