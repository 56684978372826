import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as currentUserReducer } from 'src/slices/current-user';
import { reducer as enrollmentReducer } from 'src/slices/enrollment';
import { reducer as providerReducer } from 'src/slices/providers';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  currentUser: currentUserReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  enrollment: enrollmentReducer,
  providers: providerReducer
});

export default rootReducer;
