import _ from 'lodash';
import {
  colors,
  createMuiTheme,
  responsiveFontSizes
} from '@material-ui/core';
import type { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme';
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import type {
  Palette as MuiPalette,
  PaletteColor,
  TypeBackground as MuiTypeBackground
} from '@material-ui/core/styles/createPalette';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

interface TypeBackground extends MuiTypeBackground {
  dark: string;
  primary?: PaletteColor;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
  third?: PaletteColor;
  blue?: PaletteColor;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface ThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: MuiShadows;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.custom-scroll::-webkit-scrollbar': {
          width: 4,
          backgroundColor: colors.blueGrey[100],
        },
        '.custom-scroll::-webkit-scrollbar-track': {
          backgroundColor: colors.blueGrey[100],
        },
        '.custom-scroll::-webkit-scrollbar-thumb': {
          backgroundColor: colors.blueGrey[700],
        },
        'a': {
          textDecoration: 'none',
        }
      }
    },
    MuiCard: {
      root: {
        border: `1px solid ${colors.grey[200]}`,
        boxShadow: 'none',
        borderRadius: 16,
      }
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255,255,255,0.9)',
      }
    },
    MuiButton: {
      root: {
        borderRadius: 100
      }
    }
  }
};

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.OV,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      },
      MuiOutlinedInput: {
        root: {
          '& fieldset': {
            borderColor: '#eaeaea',
          }
        }
      },
      MuiToolbar: {
        root: {
          backgroundColor: '#dfecef',
          color: '#3ba3b3',
        }
      },
      MuiListSubheader: {
        root: {
          color: '#3ba3b3',
        }
      },
      MuiLinearProgress: {
        barColorPrimary: {
          backgroundColor: '#698198',
        }
      },
    },
    palette: {
      type: 'light',
      primary: {
        main: '#4e9aac',
        contrastText: '#fff',
      },
      secondary: {
        main: '#EE824D',
        contrastText: '#fff',
      },
      third: {
        main: '#2A4C6B',
        contrastText: '#fff',
      },
      blue: {
        main: '#114D71',
        contrastText: '#fff'
      },
      text: {
        primary: '#3ba3b3',
        secondary: '#698198',
        blue: '#114D71',
        third: '#2A4C6B',
        disabled: 'rgba(105,129,152,0.38)',
        hint: '#687F92'
      },
      background: {
        default: '#F4F4F4',
        primary: {
          main: '#3ba3b3',
          light: '#EBF6F6',
        },
        dark: '#f4f6f8',
        paper: colors.common.white,
        grey: {
          main: '#5FA0B0',
          contrastText: '#fff',
        },
      },
      divider: '#dfecef',
    },
    shadows: softShadows
  },
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: colors.indigo[600]
      },
      secondary: {
        main: '#5850EC'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
}
