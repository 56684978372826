import React from 'react';
import { FC } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import type { Theme } from 'src/theme';
import Account from './Account';
import Search from './Search';
import Notifications from './Notifications';
import { AuthService } from 'src/lib/authService';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: '0px 4px 15px rgba(219, 219, 219, 0.25)',
  },
  logo: {
    marginRight: theme.spacing(2),
    maxWidth: 200,
    maxHeight: 64,
  },
  toolbar: {
    background: '#F3FAFA'
  },
  secondaryToolbar: {
    background: theme.palette.background.primary?.light
  },
  navItem: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  iconButton: {
    width: 40,
    height: 40,
    padding: 0,
    color: '#838383'
  }
}));

const authService = AuthService.getInstance<AuthService>();

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async (): Promise<void> => {
    try {
      await authService.logout();
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <AppBar
      id="header"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo className={classes.logo} />
          </RouterLink>
        </Hidden>
        <Box
          ml={2}
          display="flex"
          justifyContent="center"
          alignItems="center" flexGrow={1}
        >
          <Search />
        </Box>
        <Box ml={2} display="flex" alignItems="center">
          <IconButton className={classes.iconButton}>
            <HelpIcon />
          </IconButton>
          <Notifications />
          <Account handleLogout={handleLogout} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};


export default TopBar;
