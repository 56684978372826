/// <reference types="@types/segment-analytics" />
import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer
} from 'react';
import { FC, ReactNode } from 'react';
import { User } from 'shared';
import SplashScreen from 'src/components/SplashScreen';
import { instanceApi } from 'shared';
import { AuthService } from 'src/lib/authService';
import { MediaFileService } from 'shared';
import { useLocation } from 'react-router-dom';
import { getUserById, setProvider } from 'src/slices/current-user';
import { getEnrollmentSchedules } from 'src/slices/enrollment';
import { IParent } from 'shared';
import { useDispatch } from 'src/store';
import { ParentService, ParentProviderService } from 'shared';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  accessToken?: string;
  parentId?: string;
  parent?: IParent;
  userProfile?: User;
}

interface AuthContextValue extends AuthState {
  method: 'OIDC';
  login: () => Promise<void>;
  logout: () => void;
  setLogin: (accessToken: string, user: any) => Promise<void>;
  register: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    accessToken?: string;
    parentId?: string;
    parent?: IParent;
    userProfile?: User;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    parentId: string;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action = InitialiseAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  accessToken: null,
  parent: null,
  userProfile: null
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    instanceApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete instanceApi.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      return {
        ...state,
        isInitialised: true,
        ...action.payload
      };
    }
    case 'LOGIN': {
      const { user, parentId } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        parentId
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    Appcues: any;
  }
}

const usePageViews = () => {
  const location = useLocation();
  React.useEffect(() => {
    const url = new URL(window.location.href);
    var domain = url.hostname.substring(url.host.lastIndexOf('.') + 1);

    window.analytics.page(location.pathname, null, {
      environment: process.env.NODE_ENV,
      user: 'Parent',
      domain
    });
    window.Appcues.page();
  }, [location]);
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'OIDC',
  login: () => Promise.resolve(),
  logout: () => {},
  setLogin: (accessToken: string, user: any) => Promise.resolve(),
  register: () => Promise.resolve()
});

const authService = AuthService.getInstance();
const parentService = ParentService.getInstance<ParentService>();
const parentProviderService = ParentProviderService.getInstance<
  ParentProviderService
>();

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  usePageViews();

  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const sliceDispatch = useDispatch();

  /**
   * Redirects to OIDC login page
   */
  const login = async () => {
    await authService.login();
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const afterLogin = useCallback(async () => {
    const parentId = await parentService
      .getCurrentParent()
      .then(({ data }) => data);
    const { data } = await parentProviderService.getProvidersByParent();
    if (data?.items.length > 0) {
      sliceDispatch(setProvider(data.items[0]));
      await sliceDispatch(
        getEnrollmentSchedules({
          providerId: data.items[0]?.providerId,
          ChildAge: 0
        })
      );
    }
    await sliceDispatch(getUserById(parentId));
    await MediaFileService.init();
    return parentId;
  }, [sliceDispatch]);

  const setLogin = async (accessToken, user) => {
    setSession(accessToken);
    const parentId = await afterLogin();

    window.Appcues.identify(user?.profile?.sid, {
      role: 'Provider',
      parentId: parentId
    });

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        parentId: parentId
      }
    });
  };

  /**
   * Redirects to OIDC register page
   */
  const register = async () => {};

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {
          setSession(accessToken);

          // TODO: we can make call to API here (we get token already) to get more details about user, e.g. avatar
          // For now we use what we get from OIDC provider instead (but that not include avatar and probably other things)
          // const response = await instanceApi.get<{ user: User; }>('/api/account/me');
          // const { user } = response.data;

          let userData = await authService.getUser();
          let userOv = {};
          if (!userData) {
            try {
              await authService.signinRedirectCallback();
              userData = await authService.getUser();
            } catch (error) {
              console.log(error);
            }
            if (!userData) {
              const userInfo = await authService.getOVUser(accessToken);
              userOv = {
                avatar: null,
                ...userInfo
              };
            }
          }

          const parentId = await afterLogin();
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              accessToken,
              user: userData ? userData : userOv,
              parentId: parentId
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              accessToken: null,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            accessToken: null,
            user: null
          }
        });
      }
    };

    initialise();
  }, [afterLogin]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'OIDC',
        login,
        logout,
        register,
        setLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
