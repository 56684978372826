import React from 'react';
import type { FC } from 'react';
import {
  IconButton,
  makeStyles
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 320
  },
  iconButton: {
    width: 40,
    height: 40,
    padding: 0,
    color: '#838383'
  }
}));

const Notifications: FC = () => {
  const classes = useStyles();
  return (
    <IconButton className={classes.iconButton}>
      <NotificationsIcon />
    </IconButton>
  );
};

export default Notifications;
