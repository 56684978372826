import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { Theme } from 'src/theme';

interface FlowLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.paper,
      height: '100%'
    },
    logo: {
      position: 'absolute',
      left: theme.spacing(3),
      top: theme.spacing(3)
    },
    pageBody: {}
  };
});

const FlowLayout: FC<FlowLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box className={classes.pageBody}>
        <img
          className={classes.logo}
          src="/static/images/layout/logo.svg"
          alt="logo"
        />
        {children}
      </Box>
    </div>
  );
};

FlowLayout.propTypes = {
  children: PropTypes.node
};

export default FlowLayout;
