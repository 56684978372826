import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Link,
  makeStyles,
  colors,
  Container
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { oidcConfig } from 'src/config';

interface TopBarProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.common.white,
    boxShadow: 'none',
  },
  toolbar: {
    height: 64,
    backgroundColor: 'transparent',
  },
  logo: {
    marginRight: theme.spacing(2),
    maxWidth: 200,
    maxHeight: 64,
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  registerButton: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    background: '#ebf7f7',
    '&:hover': {
      backgroundColor: '#8fb8c0',
      color: colors.common.white,
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  let parentAuthority = `${oidcConfig.stsAuthority}`;
            parentAuthority = parentAuthority.endsWith('/') ? parentAuthority : `${parentAuthority}/`;

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Container maxWidth={false}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <Box flexGrow={1} />
        <Link
          className={classes.link}
          color="textPrimary"
          component={RouterLink}
          to="/app"
          underline="none"
          variant="body2"
        >
          Login
        </Link>
        <Button
          color="primary"
          className={classes.registerButton}
          component="a"
          href={`${parentAuthority}Account/Register?ReturnUrl=${oidcConfig.clientRoot}signin-oidc?page=app/dashboard`}
          size="medium"
        >
          Register
        </Button>
        
      </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
