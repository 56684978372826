import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { ParentProviderService } from 'shared';

export interface ProgramsState {
    isLoading: boolean;
    providers: any[];
};

const initialState: ProgramsState = {
    isLoading: false,
    providers: []
};

const slice = createSlice({
    name: 'parentProviders',
    initialState,
    reducers: {
        setLoading(state: ProgramsState, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setParentProviders(state: ProgramsState, action: PayloadAction<any>) {
            state.providers = action.payload;
        }
    }
});

export const reducer = slice.reducer;

const parentProviderService = ParentProviderService.getInstance<
    ParentProviderService
>();

export const getParentProviders = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setLoading(true));
    return parentProviderService.getProvidersByParent().then((response) => {
        const { data } = response;
        if (data?.totalCount > 0) {
            dispatch(slice.actions.setParentProviders(data?.items));
        }
        dispatch(slice.actions.setLoading(false));
        return response;
    }).catch(() => {
        dispatch(slice.actions.setLoading(false));
    })
}